import moment from 'moment'
export default {
  tsFormatTime(value) {
    if (value !== 0) {
      return moment
        .utc(value * 1000)
        .format('HH小时mm分ss秒')
        .replace('00小时', '')
        .replace('00分', '')
        .replace('00秒', '')
    } else {
      return '0秒'
    }
  },
  settledFilter(maxValue, minValue, type) {
    if (type === 'wan_online') {
      return '未结算'
    } else {
      if (maxValue === 0 && minValue === 0) {
        return '未结算'
      } else if (maxValue >= 1 && minValue === 0) {
        return '已结算/未结算'
      } else {
        return '结算'
      }
    }
  }
}
