import axios from "axios";
// import { Message } from 'element-ui'
console.log("process.env", process)
// ${process.env.VUE_APP_ORG_ID}
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, //添加于请求路径前
  timeout: 5000, // request timeout
});
service.interceptors.request.use(
  //请求拦截
  (config) => {
    const token = sessionStorage.getItem('token')
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdJZCI6IjJuWEhYd2E3M3g5ZFFxZmhiTU5NOFEiLCJhY2NvdW50SWQiOiJ2a2kzdlU0emFFaWR6Nm1DaWM0NFUiLCJhY2NvdW50IjoiYWRtaW5AcXEuY29tIiwiYWNjb3VudFR5cGUiOiJlbWFpbCIsInJvbGVzIjoiMm5YSFh3YTczeDlkUXFmaGJNTk04UTpvcmc6YWRtaW4iLCJzaG9wX2lkIjpudWxsLCJyZWZyZXNoS2V5IjoiS3ZweEFjVkNKQjFGdk1uNEJ1VndyQT09IiwiaWF0IjoxNjIwNjE4MjY0LCJleHAiOjE2MjA2NjE0NjR9.wqO_hhwXwcEnDWKjFtM-iFkMkK-AnfhCUdF4_xlh_-E"
    if (token) {
      config.headers["authorization"] = token; //在请求之前，像 headers 里面携带token
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  //对请求回来的数据做统一错误处理
  (response) => {
    const res = response;
    if (res.data.code !== 1) {
      //   Message({
      //     message: res.data.message || "不明错误",
      //     type: "error",
      //     duration: 5 * 1000,
      //   });
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200) {
      //   Message({
      //     message: res.message || "不明错误",
      //     type: "error",
      //     duration: 5 * 1000,
      //   });
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  }
);

export default service;
