<template>
  <div class="record">
    <div class="record_header">
      <div class="record_header_box">
        <el-tabs v-model="dateType" @tab-click="handleClick">
          <el-tab-pane label="今日" name="day"></el-tab-pane>
          <el-tab-pane label="本周" name="week"></el-tab-pane>
          <el-tab-pane label="本月" name="month"></el-tab-pane>
          <el-tab-pane label="今年" name="year"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="record_header_box record_header_right">
        <div class="record_header_right_settlement">
          <span>是否结算：</span>
          <el-select
            v-model="settled"
            placeholder="请选择"
            @change="onSettledSelected"
          >
            <el-option label="全部" value="all"></el-option>
            <el-option label="已结算" value="true"></el-option>
            <el-option label="未结算" value="false"></el-option>
          </el-select>
        </div>
        <el-date-picker
          v-model="dateSpan"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :shortcuts="shortcuts"
          @change="onDateSpanChange"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="export_box">
      <el-button type="primary" @click="exportExcel('round_id')"
        >导出场次战绩excel</el-button
      >
      <el-button @click="exportExcel('')" type="primary" class="button"
        >导出全部战绩excel</el-button
      >
    </div>
    <el-table
      :data="tableData"
      class="table"
      ref="multipleTable"
      header-cell-class-name="table-header"
      @row-click="goToRoundDetail"
    >
      <el-table-column
        label="编号"
        type="index"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="game_name"
        label="游戏名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="round_id"
        label="场次ID"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="shop_name"
        label="所属店铺"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="shop_city"
        label="城市"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="start_time"
        label="开始时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="end_time"
        label="开始时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="duration"
        label="游戏时长"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="settledOut"
        label="结算"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="recordsCount"
        width="80"
        label="人数"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="pages"
      background
      layout="total, prev, pager, next"
      :current-page="currentQueryPage + 1"
      :page-size="pageSize"
      :total="totalItems"
      @current-change="onPaginationChange"
    ></el-pagination>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, defineComponent } from "vue";
import { getRecord } from "../utils/api";
import moment from "moment";
import timeFormat from "../utils/TimeFormat.js";
import { useRouter } from "vue-router";
import XLSX from "xlsx";
import FileSaver from "file-saver";
export default defineComponent({
  props: {
    type: {
      default: "",
      type: String,
    },
  },
  setup(props) {
    const router = useRouter();
    const start = new Date();
    const end = new Date();
    const state = reactive({
      dateSpan: [start, end],
      dateType: "day",
      currentQueryPage: 0,
      pageSize: 20,
      totalItems: 0,
      tableData: [],
      settled: "all",
      shortcuts: [
        {
          text: "最近一周",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "最近一个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "最近三个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
      allData: [],
    });
    const onSettledSelected = (e) => {
      getData(props.type, e);
    };
    const getData = (type, settled) => {
      const id = sessionStorage.getItem("shopId");
      const start = moment(state.dateSpan[0]).format("YYYY-MM-DD");
      const end = moment(state.dateSpan[1]).format("YYYY-MM-DD");
      const data = {
        startDate: start,
        endDate: end,
        page: state.currentQueryPage,
        size: state.pageSize,
        groupBy: "round_id",
      };
      if (type) {
        data.type = type;
      }
      if (settled !== "all") {
        data.settled = state.settled === "true" ? true : false;
      }
      getRecord(id, data).then((res) => {
        state.totalItems = res.data.total;
        let resp = res.data.results;
        state.tableData = resp.map((round, index) => {
          return {
            index: index,
            ...round,
            start_time: moment(round.start_time).format("YYYY-MM-DD HH:mm:ss"),
            end_time: moment(round.end_time).format("YYYY-MM-DD HH:mm:ss"),
            duration: timeFormat.tsFormatTime(round.duration),
            settledOut: timeFormat.settledFilter(
              round.settled,
              round.settled_min,
              round.type
            ),
          };
        });
      });
    };
    //导出excel------获取数据
    const exportExcel = async (groupBy) => {
      state.allData = [];
      const recordPageSize = 5000;
      const start = moment(state.dateSpan[0]).format("YYYY-MM-DD");
      const end = moment(state.dateSpan[1]).format("YYYY-MM-DD");
      const id = sessionStorage.getItem("shopId");
      const data = {
        startDate: start,
        endDate: end,
        page: state.currentQueryPage,
        size: recordPageSize,
      };
      if (props.type) {
        data.type = props.type;
      }
      if (state.settled !== "all") {
        data.settled = state.settled === "true" ? true : false;
      }
      if (groupBy) {
        data.groupBy = groupBy;
      }
      console.log("eeeeee", data);
      const resq = await getRecord(id, data);
      const allTotal = resq.data.total;
      const pages = Math.ceil(allTotal / 5000);
      for (let i = 0; i < pages; i++) {
        const data1 = {
          startDate: start,
          endDate: end,
          page: i,
          size: recordPageSize,
        };
        if (props.type) {
          data1.type = props.type;
        }
        if (state.settled !== "all") {
          data1.settled = state.settled === "true" ? true : false;
        }
        if (groupBy) {
          data1.groupBy = groupBy;
        }
        const res = await getRecord(id, data1);
        state.allData = [...state.allData, ...res.data.results];
      }
      let excelItems;
      if (groupBy) {
        excelItems = state.allData.map((round) => {
          return {
            round_id: round.round_id,
            game_name: round.game_name,
            shop_name: round.shop_name,
            shop_city: round.shop_city,
            start_time: moment(round.start_time).format("YYYY-MM-DD HH:mm:ss"),
            settled: timeFormat.settledFilter(round.settled, round.settled_min),
            duration: timeFormat.tsFormatTime(round.duration),
          };
        });

        excelItems.unshift({
          round_id: "场次ID",
          game_name: "游戏名称",
          shop_name: "门店名称",
          shop_city: "门店城市",
          start_time: "开始时间",
          settled: "是否结算",
          duration: "持续时间",
        });
      } else {
        excelItems = state.allData.map((round) => {
          return {
            game_name: round.game_name,
            round_id: round.round_id,
            shop_name: round.shop_name,
            shop_city: round.shop_city,
            nickname: round.player_name,
            unionId: round.player_id,
            start_time: moment(round.start_time).format("YYYY-MM-DD HH:mm:ss"),
            settled:
              round.settled === 0
                ? "不结算"
                : round.settled === 1 || round.settled === 2
                ? "结算"
                : "",
            duration: timeFormat.tsFormatTime(round.duration),
          };
        });

        excelItems.unshift({
          game_name: "游戏名称",
          round_id: "场次ID",
          shop_name: "所属店铺",
          shop_city: "城市",
          nickname: "昵称",
          unionId: "unionId",
          start_time: "开始时间",
          settled: "是否结算",
          duration: "持续时间",
        });
      }
      console.log("yyyyyyyyyyyyyyyy", excelItems);
      const ws = XLSX.utils.json_to_sheet(excelItems, {
        skipHeader: true,
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "回合信息");
      const wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          (groupBy ? "场次信息" : "战绩信息") + ".xlsx"
        );
      } catch (e) {
        console.log("error saving workbook");
      }
    };
    const handleClick = () => {
      const start = moment().startOf(state.dateType);
      const end = moment().endOf(state.dateType);
      state.dateSpan = [start.toDate(), end.toDate()];
      getData(props.type, state.settled);
    };
    const onPaginationChange = (page) => {
      state.currentQueryPage = page - 1;
      getData(props.type, state.settled);
    };
    const goToRoundDetail = (e) => {
      router.push({ path: "RecordDetail", query: { id: e.round_id } });
    };
    const onDateSpanChange = (data) => {
      console.log("da", data);

      getData(props.type, state.settled);
    };
    onMounted(() => {
      getData(props.type, state.settled);
    });
    return {
      ...toRefs(state),
      onSettledSelected,
      handleClick,
      onPaginationChange,
      goToRoundDetail,
      onDateSpanChange,
      exportExcel,
    };
  },
});
</script>

<style lang="scss">
.record {
  width: 100%;
  height: auto;
  overflow: hidden;
  .record_header {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .record_header_box {
      width: 30%;
    }
    .record_header_right {
      width: 70%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .record_header_right_settlement {
        margin-right: 5%;
      }
    }
  }
  .export_box {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 10px 0;
  }
  .pages {
    margin-top: 10px;
  }
}
</style>