<template>
  <div class="home">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="局域网场次详情" name="0">
        <all-record v-if="activeName === '0'" type=""></all-record>
      </el-tab-pane>
      <el-tab-pane label="广域网场次详情" name="1">
        <all-record v-if="activeName === '1'" type="wan_common"></all-record>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { toRefs, reactive } from "vue";
import allRecord from "../components/Record.vue";
export default {
  name: "Home",
  components: {
    allRecord,
  },
  setup() {
    const state = reactive({
      activeName: "0",
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss">
.home {
  width: 100%;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
}
</style>
