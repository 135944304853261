import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/recordDetail',
    name: 'RecordDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/RecordDetail.vue'),
    meta: {
      keepAlive: false
    }
  }, {
    path: '/feedback',
    name: "Feedback",
    component: () => import('../views/Feedback.vue'),
    meta: {
      keepAlive: false
    }
  },{
    path: '/resultPage',
    name: "ResultPage",
    component: () => import('../views/ResultPage.vue'),
    meta: {
      keepAlive: false
    }
  }
  
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
