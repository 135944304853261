import service from '../utils/request'
const orgid = '2nXHXwa73x9dQqfhbMNM8Q'
export async function getRecord(shopId, data) {
  const baseData = {
    order: "desc",
    orderBy: "start_time"
  };
  const returnedTarget = Object.assign(data, baseData);
  return service({
    url: `/admin/v1/organizations/${orgid}/shops/${shopId}/records`,
    method: 'GET',
    params: returnedTarget
  })
}
export function getRecordDetail(shopId, roundId) {
  return service.request({
    method: 'GET',
    url: `/admin/v1/organizations/${orgid}/shops/${shopId}/records`,
    params: {
      roundId: roundId
    }
  })
}
export function feedback(data) {
  return service.request({
    method: 'POST',
    url: '/admin/v1/public/feedback',
    data
  })
}
export function uploadImg(fileObj) {
  const formData = new FormData()
  formData.append('feedback', fileObj.file)
  formData.append('type', 'manager')
  formData.append('shop_id', sessionStorage.getItem('shopId'))
  return service.post(`/admin/v1/public/feedback/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function uploadlogApi(fileObj) {
  const formData = new FormData()
  formData.append('log', fileObj)
  formData.append('shop_id', 'bxUKaKmn4hyNaHgMxZHDJR')
  return service.post(`/admin/v1/organizations/${orgid}/log`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// /admin/v1/organizations/{orgId}/log/
// function uploadGameCover(gameId, fileObj) {
//   const formData = new FormData()
//   formData.append('cover', fileObj.file)
//   return api.post(`admin/v1/organizations/${process.env.VUE_APP_ORG_ID}/games/${gameId}/cover`, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   })
// }